.form_layout {
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: white;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important;
}

.permission_card {
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important;
}

.permission_card_head {
    background-color: white !important;
}

.permission_card_body {
    padding: 15px 30px !important;
}

.head_check .form-check-label {
    font-size: 18px !important;
}
.head_check .form-check-input {
    border: 2px solid #b2b4b6 !important;
}

.menu_check .form-check-label {
    font-size: 16px !important;
    font-weight: 600 !important;
}
.menu_check .form-check-input {
    border: 2px solid #b2b4b6 !important;
}

.sub_menu_check .form-check-label {
    color: rgb(112, 112, 112) !important;
}

.sub_menu_check .form-check-input {
    border: 2px solid #b2b4b6 !important;
}
.psw_icon {
    position: absolute;
    top: 38px;
    right: 20px;
  }