.login_bg {
    /* position: relative; */
    /* background-image: linear-gradient(to top, #5d616b, #6d7179, #a4a7af, #b9babd, #d5d6d8); */
    background-color: white;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.side-logo {
    text-align: center;
     height: 500px;
   /* width: 600px; */
}

.finalLogo_section {
    text-align: center;
    /* height: 50px; */

}
.finalLogo_section2 {
    text-align: center;
    height: 50px;
}
.finalLogo2 {
    max-height: 100%;

}
.finalLogo {
    /* max-height: 100%; */
    height: 25px !important;
}

.logo-img {
    height: 100% !important;
}

.login-container {
    height: 100vh;
}

.login-details {
    padding: 70px 60px 70px 60px;
    max-width: 500px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 20px;
}

.login-details h5 {
    color: rgb(131, 126, 126);
}

.login_btn {
    width: 185px;
    font-weight: 500 !important;
    background-color: #9c231b !important;
    color: white !important;
    border-radius: 5px !important;
    border: none!important;
    font-weight: 600;
    padding: 10px 45px !important;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s !important;
    background-size: 200% auto !important;
}
.login_btn:hover{
    background-position: right center !important;
    color: #fff !important;
    text-decoration: none;
}


.input_icon {
    position: absolute;
    top: 11px;
    z-index: 111999;
    border-radius: 10px;
    left: 10px;
}
.form-login-input {
    border: 2px solid rgb(167, 167, 167) !important;
    color: #1a1a27;
    padding: 10px 10px 10px 40px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    font-weight: 500;
}
.form-login-input:focus {
    border: 2px solid #46484d !important;
    box-shadow: none !important;
}
.form-login-input::placeholder{
    color: #c9cdd183;
    font-weight: 500;
}