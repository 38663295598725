.ag-theme-alpine {
    /* --ag-header-height: 30px; */
    --ag-header-foreground-color: rgb(99, 98, 98) !important;
    --ag-header-background-color: white;
    /* --ag-header-cell-hover-background-color: rgb(187, 151, 242); */
    /* --ag-header-cell-moving-background-color: rgb(80, 40, 140); */
    --ag-background-color: white;
    /* --ag-odd-row-background-color: #daedc8; */
}

.ag-root-wrapper.ag-layout-normal {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important; */
}

.ag-input-field-input {
    color: black !important;
}

.ag-icon-filter::before {
    display: none;
}

.ag-floating-filter-button-button {
    display: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none !important;
    border-color: none;
    border-style: none;
    outline: 0;
}
.ag-header-cell-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
}

.ag-cell-value,
.ag-group-value {
    line-height: normal !important;
    align-items: center !important;
    display: flex !important;
    padding: 5px;
}

.ag-cell {
    font-size: 15px !important;
}
.ag-select:not(.ag-cell-editor, .ag-label-align-top) {
    min-height: var(--ag-list-item-height);
    display: none !important;
}
.pgn_tbl_footer{
    background-color: white;
    border-bottom: solid 2px #46404d63;
    border-left: solid 2px #46404d63;
    border-right: solid 2px #46404d63;
    padding: 5px;
}

.table_footer_pagination p{
    margin-bottom: 0px !important;
}
.pg_btn{
    border: none !important;
}


.refresh_btn {
    background-image: linear-gradient(to right top, rgb(230, 43, 64), rgb(207, 45, 64), rgb(212, 57, 75), rgb(230, 43, 64), rgb(231, 39, 62));
    color: white !important;
    border-radius: 5px !important;
    padding: 7px;

}

.table_header tr th{
    color: rgb(99, 98, 98) !important;
}

.table_img_sec {
    height: 70px !important;
    width: 100px !important;
    padding: 10px 0px;

}

.table_data_img {
    max-height: 100% !important;
}
.Spreadsheet{
    border: solid 2px rgb(202, 202, 202) !important;
}
.Spreadsheet table tr th {
    border: solid 2px rgb(104, 102, 102) !important;
    color: rgb(233, 60, 60);
}
.Spreadsheet table tr td {
    border: solid 2px rgb(104, 102, 102) !important;
    color: black;
}