.msg_sidebar {
    background-color: #b8b3b3 !important;
    /* padding: 15px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.side_sec{
    height: 72.50vh;
}

.msg_section {
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.msg_btn {
    padding: 5px 10px;
    border: none !important;
    width: 100% !important;
    background: none !important;
    background-color: #fafafa  !important;
    border-radius: 10px !important;
    margin-top: 8px;
}

.msg_btn:hover {
    background-color: #818080 !important;
}

.msg_btn:focus {
    background-color: #818080 !important;
    border-radius: 10px !important;
}
.msg_head_2 {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.msg_head_2 p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
}

.msg_section_center {
    height: 70vh;
}

.send_msg h6 {
    background-color: rgb(230, 43, 64);
    margin-top: 5px;
    padding: 10px 15px 10px 15px;
    display: inline-block;
    color: white;
    border-radius: 10px 10px 10px 0px;
}

.send_section {
    padding-right: 370px;
}

.send_section p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 3px;
    margin-top: -5px;
}


.recieve_msg h6 {

    background-color: #105684b6;
    margin-top: 5px;
    text-align: left;
    padding: 10px 15px 10px 15px;
    color: white;
    display: inline-block;
    border-radius: 10px 10px 0px 10px;
}

.recieve_msg p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    margin-top: -5px;
}

.recieve_section {
    padding-left: 370px;
}

.recieve_section p {
    margin-bottom: 0px;
    font-size: 12px;
    padding-right: 3px;
    font-weight: 500;
    margin-top: -5px;
}

.chatimg_section {
    display: flex;
    justify-content: center;
    height: 60vh;
}

.chat_img {
    height: 100% !important;
    align-items: center !important;

}

.doc_down{
    background-color: #9d9d9d !important;
    padding: 20px 20px 20px 22px !important;
    border-radius: 20px !important;
}