.layout_head h3 {
    color: rgb(99, 98, 98);
}
.empty_section{
    padding: 30px;
    background-color: white;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important;
    border-radius: 10px;
    text-align: center;
}
.empty_section span{
    color: #9d9d9d;
    font-size: 18px;
    font-weight: 500;
}
