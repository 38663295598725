#property-header {
    height: 65px;
    padding: 15px;
    /* margin-right: 3px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  
  }
  
  .sidebar-btn {
    margin-left: 20px;
  }
  
  .header-logo-section {
    height: 24px !important;
    /* width: 180px !important; */
  }
  
  .header-logo {
    height: 100% !important;
    width: 100% !important;
    align-items: center !important;
  }
  .header-logo-section2 {
    height: 30px !important;
  }
  
  .header-logo2 {
    max-height: 100% !important;
    max-width: 100% !important;
    align-items: center !important;
  }

  .profile_drop_toggal::after {
    display: none !important;
  }
  
  .profile_drop {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
    border: none !important;
  }
  
  .profile_drop li a {
    font-size: 16px !important;
    font-weight: 600;
  }
  
  .profile_drop ul li a:focus {
    background-color: none !important;
  }