.dashboard_head h3 {
    color: #121136;
    font-weight: 500 !important;
}

.dashboard_card {
    padding: 30px 25px;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important;
    transition: transform .3s;

}


.dashboard_card:hover {
    cursor: pointer;
    transform: scale(1.06);
    /* padding: 35px 25px; */
    transition: all ease 0.5s;
}
.dashboard_card h4 {
    font-weight: 700 !important;
    font-size: 18px;
    color: #e42256d0;
    margin-bottom: 0px;
}

.dashboard_card h3 {
    color: rgb(94, 94, 94) !important;
    margin-top: 20px;
    font-size: 22px;
    margin-bottom: 0px;
}


/* ------------------------------- */

.dashboard_card2 {
    height: 400px !important;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 4px 0px 0px rgba(0, 0, 0, 0.07) !important;
    transition: transform .3s;

}
.dashboard_card2 h5{
    text-align: center;
    color: #e42256d0;
    font-weight: 700 !important;
}
.dashboard_card2 p{
    margin-bottom: 0px;
    color: rgb(20, 20, 20);
    font-size: 17px;
    font-weight: 500;

}
.dashboard_card2 span{
    margin-bottom: 0px;
    color: rgb(85, 85, 85);
    font-size: 14px;
    font-weight: 500;
}
.pending{
    background-color: yellow;
    color: black;
    padding: 3px 7px 3px 7px;
    font-size: 12px;
    margin-bottom: 0px !important;
    border-radius: 5px;
}
.completed{
    background-color: rgb(6, 112, 6);
    color: white;
    padding: 3px 7px 3px 7px;
    font-size: 12px;
    margin-bottom: 0px !important;
    border-radius: 5px; 
}
.cancelled{
    background-color: rgba(255, 0, 0, 0.829);
    color: white;
    padding: 3px 7px 3px 7px;
    font-size: 12px;
    margin-bottom: 0px !important;
    border-radius: 5px; 
}