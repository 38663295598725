.details_sidebar {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    height: 80vh;
}
.font-size {
    font-size: 12px !important;
    padding: 6px 19px !important;
}
.piils_btn .nav-link {
    color: rgb(99, 98, 98) !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    border: none !important;
    margin-top: -15px;
}

.piils_btn .nav-link.active {
    border: none !important;
    border-bottom: 2px solid #e42256d0 !important;
    color: #e42256d0 !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: #e2e3e6 !important;
}

.details_section {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
    background-color: white;
    padding: 15px 0px;
    border-radius: 20px;
    height: 79vh;
}

.details_sidebar p {
    margin-bottom: 0px;
    font-size: 16px !important;
    color: rgb(95, 94, 94);
    font-weight: 500 !important;
}

.details_sidebar span {
    margin-bottom: 0px;
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 500 !important;
}

.details_section h6 {
    color: rgb(95, 94, 94);
}

.post_img_section {
    width: 100%;
    height: 200px !important;
    display: flex;
    justify-content: center;
    align-items: center;

}

.post_img {
    max-height: 100% !important;
    /* height: 100% !important; */
    width: 100%;

}

.comment_section {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
    padding: 20px;
    border-radius: 10px;

}

.comment_section h6 {
    margin-bottom: 0px;
    font-size: 18px;
    color: #3f4042;
}

.comment_section p {
    font-size: 16px;
    margin-bottom: 2px;
    color: rgb(77, 77, 77);
    font-weight: 500;
}

.modal-body p {
    margin-bottom: 0px;
    font-size: 16px !important;
    color: rgb(95, 94, 94);
    font-weight: 500 !important;
}

.modal-body span {
    margin-bottom: 0px;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}

.chat_img_sec {
    height: 200px;
    /* width: 100px; */
    margin-bottom: 10px;
}

.chat_img {
    max-height: 100% !important;
    /* padding: 10px; */
    border-radius: 20px !important;
    max-width: 100% !important;
}

.remark_section {
    border: solid 2px grey;
    border-radius: 5px;
    /* padding: 5px 10px; */
    height: 15vh !important;
}
.remark_section span{
    font-size: 15px !important;
}
.remark_section p{
    font-size: 16px !important;
    color:  black!important;
}
