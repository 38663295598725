.form_label {
    font-size: 14px;
    color: #46484d;
    font-weight: 500;
}

.form_input {
    border: 2px solid #b2b4b6 !important;
}

.form_input:focus {
    border-color: #46484d !important;
    box-shadow: none !important;
}

.react_select .select__control {
    border: 2px solid #b2b4b6 !important;
    border-radius: 0.375rem !important;
    font-weight: 600;
}

.react_select .css-t3ipsp-control:focus {
    border-color: #46484d !important;
}

.react_select .css-t3ipsp-control {
    box-shadow: 0 0 0 2px #46484d !important;
}

.uniq_code {
    background-color: #dddde2;
    padding: 5px 20px;
    border-radius: 5px;
    position: absolute;
    right: 2px;
    top: 34px;
}
.flatpickr-wrapper{
    width: 100% !important;
}
