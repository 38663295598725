.save_btn {
    width: 130px;
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    border-radius: 5px !important;
    background-color: #9c231b !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 8px 0px !important;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s !important;
    background-size: 200% auto !important;
}
/* .save_btn:hover{
    background-position: right center !important;
    color: #fff !important;
    text-decoration: none;
} */

.add_btn {
    /* width: 130px; */
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    background-color: #9c231b !important;
    border-radius: 5px !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 8px 10px !important;
    text-align: center;
    text-transform: uppercase;
    /* transition: 0.5s !important; */
    /* background-size: 200% auto !important; */
}

.add_attend{
    background-color: #4a4c50;
    padding: 10px 5px;
    border-radius: 10px;
}
.excel_dwl{
    background-color: #1a912a;
    padding: 8px 6px;
    border-radius: 6px; 
}
.excel_dwl2 {
    background-color: #105684;
    padding: 8px 8px;
    border-radius: 6px;
}
.excel_dwl3{
    background-color: rgb(255, 136, 0);
    padding: 8px 8px;
    border-radius: 6px;
}
.add_attend{
    background-color: #4a4c50;
    padding: 10px 5px;
    border-radius: 10px;
}
.del_btn {
    /* width: 130px; */
    font-weight: 500 !important;
    background-image: linear-gradient(to right top, #f32626, #f32626, #f32626, #f32626, #f32626);
    color: white !important;
    border-radius: 5px !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 8px 10px !important;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s !important;
    background-size: 200% auto !important;
}
.add_btn2{
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    background-color: #1a912a !important;
    border-radius: 5px !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 8px 10px !important;
    text-align: center;
    text-transform: uppercase;
}
.tooltip_msg2{
    background-color: #1a912a !important;
    color: white !important;
}
.tooltip_msg3{
    background-color: #105684 !important;
    color: white !important;
}
.rout_btn{
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: #70747e !important;
    background-color: white !important;
    border-radius: 5px !important;
    border: solid 2px #70747e !important;
    /* border: none!important; */
    font-weight: 600 !important;
    padding: 4px 10px !important;
    text-align: center;
    text-transform: uppercase;
}
.rout_btn_act{
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    background-color: #70747e !important;
    border-radius: 5px !important;
    /* border: solid 2px #70747e !important; */
    border: none!important;
    font-weight: 600 !important;
    padding: 4px 10px !important;
    text-align: center;
    text-transform: uppercase;
}

.add_row{
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    background-color: green !important;
    border-radius: 5px !important;
    /* border: solid 2px #70747e !important; */
    width: 130px !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 2px 5px !important;
    font-size: 12px !important;
    text-align: center;
    text-transform: uppercase
}
.remove_row{
    font-weight: 500 !important;
    /* background-image: linear-gradient(to right top, #70747e, #717377, #888a8f, #8f9094, #adaeb1); */
    /* background-image: linear-gradient(to right top, #e02d29, #da4441, #da504e, #da6664, #db6866); */
    color: white !important;
    background-color: rgb(230, 31, 31) !important;
    border-radius: 5px !important;
    /* border: solid 2px #70747e !important; */
    width: 130px !important;
    border: none!important;
    font-weight: 600 !important;
    padding: 6px 5px !important;
    font-size: 12px !important;
    text-align: center;
    text-transform: uppercase
}
